<template>
  <div class="autonym-step3-content px-24">
    <div class="font-500 flex items-center">
      <div class="text-danger text-20" style="margin-right: 2px">*</div>
      上传证明材料(最多9张)
    </div>
    <!-- <div class="flex">
      <van-uploader
        class="mt-8 relative"
        multiple
        :deletable="true"
        v-model="evidenceList"
        :max-count="9"
        :after-read="afterRead"
        upload-text="上传"
      ></van-uploader>
    </div>  -->
    <div class="flex w-full flex-wrap">
      <div v-for="(item, idx) in evidenceList" :key="idx">
        <tos-uploader
          class="mt-8 mr-8"
          style="width: 1rem;height: 1rem;"
          v-model="evidenceList[idx]"
          @afterUploaded="afterUploaded"
          :max-count="1"
          @afterDelete="handleDelete(idx)"
        />
      </div>
    </div>

    <div class="mt-24" style="height:0.72rem;">
      <div class="btn-box">
        <van-button size="large" :disabled="submitLoading" @click="handleSubmit" type="primary">提交核验</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon/index.vue';
import { defineComponent, ref, onMounted } from '@vue/composition-api';
import { Button, Toast } from 'vant';
import { useIdVerifiedStore } from '@/store/idVerify';
import { uploadFile } from '@/api/file';
import { PageEnum } from '@/enums/pageEnum';
import { apiAddVerifyInfo, apiUpdateVerifyInfo } from '@/api/carrier-audit';
import { getThumbnailFileTemporaryUrlListApi } from '@/api/common';
import { Uploader as VanUploader } from 'vant';
import TosUploader from '@/components/uploader/uploader.vue';

export default defineComponent({
  name: 'customer',
  components: {
    Icon,
    Button,
    VanUploader,
    TosUploader
  },
  setup(_, ctx) {
    const useVertifiedStore = useIdVerifiedStore();

    const verifiedInfo = ref(useVertifiedStore?.verifiedInfo);

    const evidenceList = ref([[]]);
    const submitLoading = ref(false);

    const handleSubmit = () => {
      if (!evidenceList.value.length) {
        Toast.fail('证明材料不能为空');
        return;
      }

      const attachmentFileUrls = evidenceList.value
        .filter(v => !!v.length)
        .map(v => v[0].fileName)
        .join(',');
      useVertifiedStore.updateInfo({
        ...verifiedInfo.value,
        attachmentFileUrls
      });
      submitVerifiedInfo();
    };

    const afterRead = async file => {
      file.status = 'uploading';
      file.message = '上传中';
      try {
        const res = await uploadFile(file.file);
        const { data } = res;
        if (data) {
          const { url, fileName, thumbnail } = data;
          file.url = url;
          file.fileName = fileName;
          file.thumbnail = thumbnail;
        }
        file.status = 'done';
      } catch (error) {
        file.status = 'done';
        console.error(error);
      } finally {
        file.status = 'done';
      }
    };

    const submitVerifiedInfo = async () => {
      try {
        submitLoading.value = true;
        const res = verifiedInfo.value.id
          ? await apiUpdateVerifyInfo({
              ...verifiedInfo.value
            })
          : await apiAddVerifyInfo({
              ...verifiedInfo.value
            });
        const { data } = res;
        if (data) {
          Toast.success('提交成功！');
          submitLoading.value = false;
          const { $router } = ctx.root;
          $router.push(PageEnum.ID_VERIFY_INDEX);
        }
      } catch (err) {
        console.log(err);
      } finally {
        submitLoading.value = false;
      }
    };

    const afterUploaded = () => {
      if (evidenceList.value.length < 9) {
        evidenceList.value.push([]);
      }
    };

    const handleDelete = idx => {
      setTimeout(() => {
        const arr = evidenceList.value.filter(v => !!v.length);
        evidenceList.value = arr;
        evidenceList.value.push([]);
      }, 100);
    };

    onMounted(async () => {
      if (verifiedInfo.value.attachmentFileUrls) {
        const filenames = verifiedInfo.value.attachmentFileUrls.split(',');
        const newArr = [];
        const res = await getThumbnailFileTemporaryUrlListApi({ filenames });
        filenames.forEach(key => {
          if (res.data?.[key]?.url) {
            newArr.push([
              {
                url: res.data?.[key]?.url,
                fileName: key,
                status: 'done'
              }
            ]);
          }
        });
        if (newArr.length < 9) {
          newArr.push([]);
        }

        evidenceList.value = newArr;
      }
    });

    return {
      submitLoading,
      afterRead,
      verifiedInfo,
      evidenceList,
      handleSubmit,
      afterUploaded,
      handleDelete
    };
  }
});
</script>
<style lang="less">
// .autonym-step3-content {
//   .van-uploader__upload,
//   .van-uploader__preview {
//     width: 1rem;
//     height: 1rem;
//     margin-right: 8px;
//     margin-bottom: 8px;
//   }
//   .van-uploader__preview-delete {
//     display: block;
//   }
// }
</style>
